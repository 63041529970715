@import "animations";

html {
    scroll-behavior: smooth;
    font-family: $main-font;
    font-size: 16px;
    overflow-x: hidden !important;
    max-width: 100vw;
}

body {
    display: flex;
    position: relative;
    min-height: 100vh;
    flex-direction: column;
    font-family: $main-font;
    overflow-x: hidden !important;

    strong {
        font-weight: 700;
    }
    p {
        font-family: $main-font;
    }
    a {
        font-family: $main-font;

        &.underline {
            text-decoration: none;
            &:hover {
                text-decoration:underline;
            }
        }
    }
    h3 {
        font-family: $merriweather;
        font-size:1.5rem;
        font-weight: bold;
        text-decoration: underline;
    }
    header {
        display: flex;
        min-height: 70vh;
        background-size: cover !important;
        position: relative;
        max-width: 100vw;
        .frise  {
            position: absolute;
            bottom:0;
            left:0;
            display: block;
            width: 100vw;
            height: auto;
            content: ' ';
            overflow: hidden !important;
            img {
                width: 100%;
                @media screen and (max-width: $small-screen) {
                    width: auto;
                    height: 200px;
                }
            }
        }

        .top {
            padding:40px 40px 0 40px;
        }
        h1 {
            font-size: 2.5em;
            font-weight: 900;
            position:relative;
            font-family: $merriweather;
            display: inline-block;
            color: white;
            background: rgba(21, 52, 72, 0.95);
            width: max-content;
            max-width: 55vw;
            padding:65px 80px 100px 80px;
            border-radius:20px 20px 0 0;
            opacity: 0;
            transform: translateY(20%);
            transition: 0.4s;
            margin-left:200px;
            text-align: center;
            //box-shadow: inset 5px 5px 8px rgba(0,0,0,0.7), inset -15px -8px 14px rgba(0,0,0,0.5), inset -5px -4px 7px rgba(0,0,0,0.3);
            text-shadow: 0px 0px 4px rgba(0,0,0,0.9);

            i {
                position: absolute;
                top: 16px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 2.2rem;
                text-shadow: none;
            }

            .logos {
                position: absolute;
                top:0;
                left: 20px;
                transform:  translateY(-50%);

                img {
                    filter: drop-shadow(5px 5px 3px rgba(0, 0, 0, 0.7));
                    &.offset {
                        transform: translateY(-15%);
                    }
                    &.offset-n {
                        transform: translateY(10%);
                    }
                }
            }

            &.visible {
                opacity: 1;
                transform: translateY(0) rotate(-5deg);
            }

            @media screen and (max-width: $small-screen) {
                max-width: 80vw;
                border-radius: 10px;
                transform: none;
                margin-left:0;
                z-index: 99;
                padding: 30px 50px;

                &.visible {
                    transform: none;
                    margin-left:0;
                }
                &:after {

                }
            }
            @media screen and (min-width: $small-screen-up) {
                padding-bottom: 150px;
            }
            @media screen and (min-width: $large-screen-up) {
                padding:60px 80px 190px 80px;

                &.visible {
                    opacity: 1;
                    transform: translateY(0) rotate(-4deg);
                }
            }
        }
        p {
            &.head-p {
                font-size: 1.2rem;
                max-width: 30vw;
                border-radius: $radius;
                color: white;
            }
        }

        .header-main-side {
            position: absolute;
            bottom:-20px;
            width: 100%;
            align-items: start;
            @media screen and (max-width: $small-screen) {
                top:35%;
                align-items: center;
            }
        }
        .header-right-side {
            z-index:9991;
            padding-top:95px;
            padding-left: 30px;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            @media screen and (max-width: $small-screen) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-right: 30px;
            }
        }
    }

    [data-tippy-root] {
        z-index: 99999999999 !important;
        border-radius: 5px;
        box-shadow: 15px 15px 10px rgba(0,0,0,0.1);
        h5 {
            font-weight: bold;
            font-size: 1.4rem;
            font-family: $main-font;
            color: $blue-moulin;
        }
    }
}


.container {
    width: 86%;
}

.big {
    font-size:1.3rem !important;
}

.bold {
    font-weight: bold !important;
}

.main-font {
    font-family: $main-font !important;
}

main {
    flex: 1 0 auto;
    margin-top:-10px;
    z-index: 999;
    max-width: 100vw;
    iframe {
        @media screen and (max-width: $small-screen) {
            max-width: 80vw;
        }
    }
    .main-container {
        background: rgba(255, 255, 255, 1);
        display: flex;
        min-height: calc(100vh - 180px);
        margin-top:100px;
        position: relative;
    }
}

.row {
    min-width: 100%;
}

a {
    &.btn {
        box-shadow: none;
        border-radius: 5px;
        border: none;
        padding: 8px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        max-width: max-content;
        @media screen and (max-width: 1200px) {
            padding:0 20px;
        }
    }
}

.appear-from-right {
    animation: appear-from-right 0.35s ease-in-out;
}

.section {
    position: relative;
    padding:50px 7%;
    &[data-name] {
        &[data-position="left"] {
            &:before {
                left:75px;
            }
        }
        &[data-position="right"] {
            &:before {
                right:75px;
            }
        }
        &:before {
            position: absolute;
            content: attr(data-name);
            display: block;
            bottom:100%;
            height:50px;
            line-height: 50px;
            text-align: center;
            padding:0 30px;
            border-radius: 5px 5px 0 0;
        }
        &.orange {
            &:before {
                background: #ff9800;
                color: white;
            }
        }
        &.cyan {
            &:before {
                background: #00bcd4;
                color: white;
            }
        }
    }
}

hr {
    display: block;
    width: 100%;
    border: none;
    height: 1px;
    background: $dark-grey;
    margin-bottom: 15px;
}