.home-cards {

  .card {
    width: 100%;
    .card-action {
      .home_action {
        font-size:0.8rem;
      }
    }
  }

  .home-card {
    transition: 0.3s ease-in-out;
    transform: scale(0.93);
    border: 1px solid #ccc;
    border-radius: 10px;

    &:hover {
      transform: scale(1);
      box-shadow: 5px 7px 10px rgba(0,0,0,0.15);
    }
  }
}

.parallax-container {
  position: relative;

  .next-event {
    position:absolute;
    right:20px;
    top:20px;
    width: 35vw;
    height: calc(100% - 40px);
    background: white;
    display: block;
    border-radius: $radius;
    box-shadow: -5px 0px 6px rgba(0,0,0,0.6);
    .image {
      overflow: hidden;
      border-radius: $radius $radius 0 0 ;
      position: relative;
      .event-date {
        position: absolute;
        bottom:4px;
        left:0px;
        background: rgba(0,0,0,0.7);
        color: white;
        padding:4px 8px;
        font-weight: 700;
        font-size: 1.1rem;
      }
    }

    .content {
      padding:20px;
      h3 {
        font-family: $main-font;
        font-size: 2rem;
        font-weight: 400;
      }
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.block-card {
  box-shadow: none;
  opacity:0;
  transform: translateY(30%) scale(0.95);
  transition: all 0.3s linear;
  &.visible {
    opacity:1;
    transform: translateY(0%) scale(0.95);
  }
  &:hover {
    transform: translateY(0%) scale(1);

    .card-content {
      .card-title {
        &.orange-effect {
          color: darkorange;
        }

        &.teal-effect {
          color: teal;
        }

        &.light-green-effect {
          color: yellowgreen;
        }

        &.cyan-effect {
          color: deepskyblue;
        }

        &.brown-effect {
          color: saddlebrown;
        }

        &.purple-effect {
          color: #880e4f;
        }
        &.pink-effect {
          color: #d00772;
        }
        &.blue-effect {
          color: #0e35b6;
        }
        &.amber-effect {
          color: #ffae00;
        }
        &.orange-effect {
          color: #f15f05;
        }

      }
    }
    .card-action {
      a {
        transition: all 0.3s linear;
      }
    }
  }
  .card-content {
    .card-title {
      font-weight: 400;
      font-family: $main-font;
      position: relative;
      transition: all 0.3s ease-in-out;


      &.orange-effect {
        &:before {
          background: darkorange;
        }
      }

      &.teal-effect {
        &:before {
          background: teal;
        }
      }

      &.light-green-effect {
        &:before {
          background: yellowgreen;
        }
      }

      &.cyan-effect {
        &:before {
          background: deepskyblue;
        }
      }

      &.brown-effect {
        &:before {
          background: saddlebrown;
        }
      }

      &.pink-effect {
        &:before {
          background: #880e4f;
        }
      }

    }
    p {

    }
  }
  .card-action {
    border:none;
    a {
      transition: all 0.3s linear;
    }
    border-radius: 0 0 10px 10px;
  }
}

.program {
  .title {
    display: flex;
    justify-content: center;
    &.secondary {
      justify-content: start;
      h4 {
        padding: 10px 0;
        color: $blue-moulin;
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
      }
    }
    h4 {
      font-family: $main-font;
      font-size: 1.8rem;
      font-weight: bold;
      display: inline-block;
      padding: 10px 60px;
      background: $light-blue-grey;
      border-radius: 8px;
      position: relative;
      &:before {
        content: ' ';
        display: block;
        position: absolute;
        height: 1px;
        width:50%;
        top:50%;
        right:100%;
        background: $light-blue-grey;
      }
      &:after {
        content: ' ';
        display: block;
        position: absolute;
        height: 1px;
        width:50%;
        top:50%;
        left:100%;
        background: $light-blue-grey;
      }
      .icon {
        position: absolute;
        top:0;
        left: 50%;
        width: 100px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $light-blue-grey;
        transform: translateY(-50%) translateX(-50%);
        border-radius: 200px;
        i {
          font-size: 1rem;
          color: $blue-moulin;
        }
      }
    }
  }
}

.right-side-posts {
  background: $light-blue-grey;
  padding:10px 20px;
  border-radius:12px;
  font-family: $main-font;

  img {
    border-radius: 8px;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin:7px 0 15px 0;
  }

  .post_date {
    display: inline-block;
    margin-top:10px;
    background: black;
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
    padding:4px 7px;
    border-radius: 8px;
  }
  .post_place {
    font-weight: bold;
    margin-top:8px;
  }
}

.section {
  &.highlighted-gabare {
    width: 96vw;
    margin-left: 2vw;
    position:relative;
    border-radius: 50px;
    margin-bottom: 50px;
    h3 {
      font-family: $main-font;
      text-decoration: none;
    }
    h6 {
      font-family: $main-font;
      margin: 0;
    }

    .actions {
      display: flex;
      @media screen and (max-width: $small-screen-up) {
        display: block;
      }
      .image {
        img {
          max-width: 80%;
          border-radius: 50%;
        }
      }
    }

  }
}

@import "home2";